/** @jsx jsx */
import { Global } from "@emotion/react";
import { Fragment } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { Container, jsx } from "theme-ui";
import Footer from './Footer';
import { globalCSS } from "./globalcss";
import Nav from "./Nav";

function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default function Layout({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Fragment>
        <Global styles={globalCSS} />
        <Nav />
        <Container
          sx={{
            background: "white",
          }}
        >
          {children}
        </Container>
        <Footer />
      </Fragment>
    </ErrorBoundary>
  );
}
