import { css } from "@emotion/react";

export const globalCSS = css`
  body {
    background: #fff;
    font-family: -apple-system,BlinkMacSystemFont,"Calibri","Open Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    font-size: 16px;
    overflow-x: hidden;
  }
  h1 {
    font-family: 'Roboto Slab';
    margin: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    text-align: center;
    line-height: 1.2em;
    font-size: 2.2em;
    color: #a40000;
  }
  h2,
  h3 {
    font-family: 'Roboto Slab';
    font-size: 2em;
    margin-left: 10px;
  }
  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-family: 'Roboto Slab';
    font-size: 1.6em;
  }

  p {
    font-size: 18px;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ul > li > em {
    font-style: normal;
    margin-left: 20px;
  }

  .menu {
    @media (min-width: 768px) {
      display: none;
    }
  }
  .hamburger-react {
    justify-self: flex-end;
    margin-left: auto;
    color: white;
    margin: 0;
    padding: 0;
    margin-right: 5px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .layout-grid {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "content sidebar"
                        "content sidebar";

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-areas: "content"
                              "sidebar";
      }
  }
  .hero {
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
      min-height: 730px !important;
      .bgr {
        min-height: 730px !important;
      }
    }

    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
      min-height: 490px !important;
      .bgr {
        min-height: 490px !important;

    }
  }

  .gatsby-background-image-wrapper {
    min-height: 200px;
  }
  .gatsby-image-wrapper {
    max-width: 100%;
    width: 100% !important;
    img {
      max-width: 100%;
    }
  }
`;
