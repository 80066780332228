/** @jsx jsx */
import styled from "@emotion/styled";
import { graphql, Link, useStaticQuery } from 'gatsby';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { jsx } from 'theme-ui';
import LinkedIn from './LinkedIn';
import logo from './logo.png';
const NavContainer = styled("nav")`
`
const NavHeader = styled('header')`
  margin: auto;
  padding: ${props => props.theme.sizes['2']} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavElement = styled('div')`
  background-color: ${props => props.theme.colors.dark};
  display: flex;
  align-items: flex-start;

`
const NavInner = styled('div')`

  margin: auto;
  display: none;
  justify-content: flex-start;
  align-items: center;

  &.open {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    display: flex;
  }

  .nav-link {
    width:100%;
    transition: background-color 0.2s ease;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    padding: ${props => props.theme.sizes['2']} ${props => props.theme.sizes['2']};
    padding: 1rem;

    &:hover, &.active {
      text-decoration: none;
      background-color: ${props => props.theme.colors.link};
    }

    @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      width: auto;
    }
  }
`

const Logo = styled("img")`
  max-width: 220px;
`

const SocialLink = styled('a')`
  transition: color 0.2s ease;
  color: ${props => props.theme.colors.gray['5']};
  &:hover {
    color: ${props => props.theme.colors.dark};
  }
`

export default function Nav() {
  const { prismic: { allMain_navigations: { edges } } } = useStaticQuery(graphql`
    query MainNav {
      prismic {
        allMain_navigations(where: {}) {
          edges {
            node {
              body {
                ... on PRISMIC_Main_navigationBodyLink_list {
                  fields {
                    navigation_link {
                      ... on PRISMIC_Page {
                        _meta {
                          uid
                        }
                        nav_label
                      }
                      ... on PRISMIC_Contact_page {
                        _meta {
                          uid
                        }
                        nav_label
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const nav = edges[0].node.body[0].fields
  const [isOpen, setOpen] = useState(false)

  return <NavContainer>
    <NavHeader sx={{ width: ['90%', '90%', '80%'] }}>
      <Link className="logo" to='/'>
        <Logo src={logo} alt="Domegeo.ca Home Page" width="220" height="90" />
      </Link>
      <SocialLink aria-label="LinkedIn button" href="https://www.linkedin.com/in/istv%C3%A1n-alm%C3%A1si-52530230/">
        <LinkedIn height={30} width={30} />
      </SocialLink>
    </NavHeader>
    <NavElement>
      <Hamburger aria-label="mobile menu toggle" toggled={isOpen} toggle={setOpen} sx={{ mr: 2 }} />
      <NavInner sx={{ width: ['90%', '90%', '80%'] }} className={isOpen ? 'open' : ''}>

        {nav.map(l =>
        (
          <Link
            key={l.navigation_link._meta.uid}
            to={`${l.navigation_link._meta.uid === 'home' ? '/' : `/${l.navigation_link._meta.uid}`}`}
            activeClassName="active"
            className="nav-link"
          >
            {l.navigation_link.nav_label}
          </Link>
        )
        )}
      </NavInner>
    </NavElement>
  </NavContainer>

  // render={({prismic: {allMain_navigations: {edges}}}) => {
  //   const nav = edges[0].node.body[0].fields
  //   console.log('nav', nav)
  //   return (
  //   )
  // }}

}
