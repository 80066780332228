/** @jsx jsx */
import styled from "@emotion/styled";
import { Link } from 'gatsby';
import { MdMailOutline } from 'react-icons/md';
import { jsx, Styled } from "theme-ui";
import LinkedIn from './LinkedIn';
import logo from './logo.png';

const FooterContainer = styled('footer')`
  background-color: ${props => props.theme.colors.dark};

`
const FooterContent = styled('div')`
  padding: ${props => props.theme.sizes['5']} 0;
  max-width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
                'logo'
                'contact'
                'copy';

  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    grid-template-columns: auto minmax(200px, max-content);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'logo contact'
                          'copy contact';
  }

.logo {
    grid-area: logo;
  }
  .copyright {
    grid-area: copy;
    color: ${props => props.theme.colors.gray['6']};
  }

  .contact {
    grid-area: contact;
    display: flex;
    flex-direction: column;

    .contact-title {
      font-weight: normal;
      font-family: 'Roboto Slab';
      font-size: 1.4em;
    }
  }
  h4 {
    color: ${props => props.theme.colors.gray['6']};
  }
  a {
    color: ${props => props.theme.colors.gray['6']};
    display: flex;
    align-items: center;
    line-height: 1.8em;
    svg {
      margin-right: 10px;
    }
  }
`
const Logo = styled("img")`
  max-width: 180px;
`
const SocialLink = styled('a')`
  display: flex;
  align-items: center;
`

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <Link className="logo" to='/'>
          <Logo src={logo} alt="Domegeo.ca Home Page" width="180" height="72" />
        </Link>
        <Styled.p className="copyright">&copy; {new Date().getUTCFullYear()} domegeo.ca. All rights reserved.</Styled.p>

        <div className="contact">
          <Styled.h4 className="contact-title">Contact us</Styled.h4>
          <Link to="/contact-us"> <MdMailOutline size={20} />Send us a message!</Link>
          <SocialLink href="https://www.linkedin.com/in/istv%C3%A1n-alm%C3%A1si-52530230/">
            <LinkedIn height={20} width={20} /> LinkedIn profile
      </SocialLink>
        </div>
      </FooterContent>
    </FooterContainer>
  )
}
